//src/api/recomapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
//获取订单列表接口
export function getAdData(obj) {
    return request({
        url: '/api/admin/order',      // url = base url + request url
        method: 'post',
        params: obj,
        //----------------->>>区别
    })
}
//获取订单详情接口
export function getInfoData(obj) {
    return request({
        url: '/api/admin/order/getInfo',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//获取订单列表接口
export function getAdSerchData(obj) {
    return request({
        url: `/api/admin/order/getSearchStatus`,      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//指派师傅列表
export function getOfferWorker(obj) {
    return request({
        url: '/api/admin/order/getOfferWorker',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//指导师傅派单
export function postAssignWorker(obj,url) {
    return request({
        url: '/api/admin/order/assignWorker',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

//获取退款列表接口
export function getrefundData(obj) {
    return request({
        url: '/api/admin/refund',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//退款详情
export function postrefundDetail(obj,url) {
    return request({
        url: '/api/admin/refund/refundDetail',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//退款状态更改
export function postbatchStatus(obj,url) {
    return request({
        url: '/api/admin/refund/batchStatus',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}//修改备注
export function postbatchRemark(obj,url) {
    return request({
        url: '/api/admin/refund/batchRemark',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//申请退款
export function postapplyRefund(obj,url) {
    return request({
        url: '/api/admin/order/applyRefund',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//退款状态更改
export function postapplynoRefund(obj,url) {
    return request({
        url: '/api/admin/refund/batchStatus',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

//获取投诉列表接口
export function getcomplainData(obj) {
    return request({
        url: '/api/admin/complain',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//投诉详情
export function postcomplainDetail(obj,url) {
    return request({
        url: '/api/admin/complain/complainDetail',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//投诉状态更改
export function postcompbatchStatus(obj,url) {
    return request({
        url: '/api/admin/complain/batchStatus',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//获取退款查询按钮接口
export function getrefundSerchData(obj) {
    return request({
        url: '/api/admin/refund/getSearchStatus',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
