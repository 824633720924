<template>
  <div class="about">
    <div>
      <el-input style="width: 200px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-select clearable style="width: 200px;margin-right: 10px" v-model="refund_type" placeholder="退款类型">
        <el-option
            v-for="item in typeArr"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-select clearable style="width: 200px;margin-right: 10px" v-model="refund_reason" placeholder="退款原因">
        <el-option
            v-for="item in reasonArr"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-select clearable style="margin:0 15px" v-model="area_id" @clear="clearOption" size="medium">
                <el-option
                    v-for="item1 in region"
                    :key="item1.id"
                    :label="item1.area_name"
                    :value="item1.area_id"
                    >
                </el-option>
              </el-select>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-button  style="margin-bottom: 10px" size="mini" v-for="(item,i) in SearchStatusArr" :key="i" :class="item.status==status?'onserch':''" type="primary" plain @click="onOrderSerch(item.status)">{{item.title}} <span v-if="item.count>0" style="color: red">({{item.count}})</span></el-button>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column prop="product_logo"  width="80" label="产品图" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
            <el-image
                v-if="scope.row.product_logo"
                style="width: 50px; height: 50px"
                :src="scope.row.product_logo"
                :preview-src-list="[scope.row.product_logo]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100" prop="order_id" label="订单ID" >
      </el-table-column>
      <el-table-column width="160" prop="order_no" label="订单编号" >
      </el-table-column>
      <el-table-column width="141" prop="product_name" label="产品名称" >
      </el-table-column>
      <el-table-column width="110" prop="refund_amount" label="退款金额" >
        <template #default="scope">
          ￥ {{scope.row.refund_amount}}
        </template>
      </el-table-column>
      <el-table-column width="100" prop="type_name" label="退款类型">
      </el-table-column>
      <el-table-column prop="status_text" width="85" label="状态" >
        <template #default="scope">
          <el-tag size="mini" v-if="scope.row.status==0">{{ scope.row.status_text }}</el-tag>
          <el-tag size="mini" type="warning" v-if="scope.row.status==1">{{ scope.row.status_text }}</el-tag>
          <el-tag size="mini" type="warning" v-if="scope.row.status==2" >{{ scope.row.status_text }}</el-tag>
          <el-tag size="mini" type="danger" v-if="scope.row.status==3">{{ scope.row.status_text }}</el-tag>
          <el-tag size="mini" type="info" v-if="scope.row.status==4">{{ scope.row.status_text }}</el-tag>
          <el-tag size="mini" type="success" effect="dark" v-if="scope.row.status==5">{{ scope.row.status_text }}</el-tag>
          <el-tag size="mini" type="info" v-if="scope.row.status==6">{{ scope.row.status_text }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="reason_name" show-overflow-tooltip width="120" label="退款原因" >
      </el-table-column>
      <el-table-column prop="remark" show-overflow-tooltip width="120" label="用户备注" >
      </el-table-column>
      <el-table-column prop="worker_remark" show-overflow-tooltip width="120" label="师傅备注" >
      </el-table-column>
      <el-table-column prop="user_remark" show-overflow-tooltip width="120" label="后台备注" >
      </el-table-column>
        <el-table-column width="160" prop="create_time" label="申请时间" >
        </el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" width="260">
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button :disabled="scope.row.status==4||scope.row.status==5" size="mini" type="primary" @click="onassignWorker(scope.row)">同意退款</el-button>
                <el-button :disabled="scope.row.status==4||scope.row.status==5" size="mini" type="primary" @click="onassignWorker(scope.row,'no')">撤销退款</el-button>
                <el-button size="mini" type="primary"  @click="upData(scope.row)">修改备注</el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>

      </div>
    <el-drawer
        title="修改备注"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="备注" prop="user_remark">
          <el-input v-model="ruleForm.user_remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/orderApi"//本页面接口
import {get,post,addDq} from "@/api/Api"
export default {
  name: 'brand',
  data(){
    return{
      /*查询*/
      status:'',
      area_id:null,
      region:[],
      SearchStatusArr:{},
      refund_type:'',
      refund_reason:'',
      typeArr:[],
      reasonArr:[],
      /*查询*/
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        user_remark:'',
      },
      type:[
        {
          id:0,
          name:'客户端',
        },
        {
          id:1,
          name:'师傅端',
        },
      ],
      rules:{
        user_remark: [
          { required: true, message: '请填写内容', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
    this.onserch()
    this.onArrFun()
    this.getaddDq()
  },
  mounted() {
  // this.onserch()
  },
  methods:{
    onArrFun(){
      let data={
        page:1,
        limit:1000,
        w_type:'base',
      }
      get(data,'/api/admin/refundtype').then((res)=>{
        console.log(res)
        this.typeArr=res.data.data
      }).catch((err)=>{

      })
      get(data,'/api/admin/refundreason').then((res)=>{
        console.log(res)
        this.reasonArr=res.data.data
      }).catch((err)=>{

      })
    },
    //查询按钮
    onserch(){
      axios.getrefundSerchData({area_id:this.area_id}).then((res)=>{
        console.log(res)
        this.SearchStatusArr=res.data
      }).catch((err)=>{

      })
    },
    onOrderSerch(val){
      this.page=1
      this.status=val
      this.onTable()
    },
    //查询按钮
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        refund_type:this.refund_type,
        refund_reason:this.refund_reason,
        status:this.status,
        area_id:this.area_id
      }
        axios.getrefundData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
      this.onserch()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        user_remark:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.user_remark=row.user_remark
      this.drawer=true
    },
    onassignWorker(row,val){
      let name=''
      if(val=='no'){
        name='撤销'
      }else {
        name='同意'
      }
      this.$confirm('['+row.product_name+'] '+ name+' 退款!，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
            if(val=='no'){
              axios.postapplynoRefund({id:row.id,status:4}).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.search()
                }else {
                  this.$message.error(res.msg)
                }
              }).catch((err)=>{
                this.$message.error('服务器报错！！请联系管理员')
              })
            }else {
              axios.postapplyRefund({refund_id:row.id}).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.search()
                }else {
                  this.$message.error(res.msg)
                }
              }).catch((err)=>{
                this.$message.error('服务器报错！！请联系管理员')
              })
            }
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消',
            })
          })

      // if(val=='no'){
      //   name='撤销'
      //   this.$confirm('['+row.product_name+'] '+ name+' 退款!，是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //   }).then(() => {
      //     axios.postapplynoRefund({id:row.id,status:4}).then((res)=>{
      //       if(res.code==200){
      //         this.$message.success(res.msg)
      //         this.search()
      //       }else {
      //         this.$message.error(res.msg)
      //       }
      //     }).catch((err)=>{
      //       this.$message.error('服务器报错！！请联系管理员')
      //     })
      //   })
      //       .catch(() => {
      //         this.$message({
      //           type: 'error',
      //           message: '已取消',
      //         })
      //       })
      // }else {
      //   name='同意'
      //   this.$prompt('['+row.product_name+'] '+ name+' 退款!，填写退款金额继续！单位（元）', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     inputPattern: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
      //     inputErrorMessage: '必须是大于零的数'
      //   }).then(({ value }) => {
      //     axios.postapplyRefund({refund_id:row.id,}).then((res)=>{
      //       if(res.code==200){
      //         this.$message.success(res.msg)
      //         this.search()
      //       }else {
      //         this.$message.error(res.msg)
      //       }
      //     }).catch((err)=>{
      //       this.$message.error('服务器报错！！请联系管理员')
      //     })
      //   }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '取消输入'
      //     });
      //   });
      //
      // }


    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postbatchRemark(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.postbatchRemark(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }

        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取城市地区列表数据
    async getaddDq(){
      const {data:res} = await addDq()
      this.region = res
      this.area_id = this.region[0].area_id
      this.onTable()
      this.onserch()
    },
  }
}
</script>
<style lang="less" >
.onserch{
  background-color:#409eff ;
  color: white;
}
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>